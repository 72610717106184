<template>
  <div >
        <div class="header">
             <ul class="left_menu">
                <div class="item"> 
                    <li class="active a">规则管理</li>
               <li class=" a"><router-link to="/index4/pt">客户评论</router-link></li>
                </div>
                 <div  class="item">  
                    <li class="a"><router-link to="/index4/xiaoxi44">  <i class=" el-icon-message-solid" style="color:#0034FF;width:25px;height:25px;border-radius:100%;background: #f5f5f5;text-align:center;line-height:25px;margin-right:5px"></i>消息通知</router-link> </li>
                     <li style="cursor:pointer;" @click="dialogFormVisible = true">技术总监<i class="el-icon-caret-bottom"></i>  </li>
                </div>    

                                        <el-dialog title="个人信息" style="text-align:center" :visible.sync="dialogFormVisible">
                        <el-form :model="info">
                            <el-form-item label="昵称" :label-width="formLabelWidth">
                            <el-input v-model="info.name" autocomplete="off">  <el-button slot="append" @click="a11()">修改</el-button></el-input>
                         
                            </el-form-item>
                            <el-form-item label="登录账号" :label-width="formLabelWidth">
                             <el-input v-model="info.phone" autocomplete="off"  :disabled="true"></el-input>
                            </el-form-item>
                             <el-form-item label="登录密码" :label-width="formLabelWidth">
                             <el-input v-model="password3" placeholder="输入旧密码"  autocomplete="off">  <el-button slot="append"  @click="a22()">修改</el-button></el-input>
                            </el-form-item>
                            <el-form-item label="新密码" v-show="password2" :label-width="formLabelWidth">
                             <el-input v-model="password" autocomplete="off">  <el-button slot="append"  @click="a33()">确认修改</el-button> </el-input>
                            </el-form-item>
                        </el-form>
                        <div slot="footer" class="dialog-footer">
                            <el-button type="primary" @click="tuichu" style="width:100%">退出登录</el-button>
                       
                        </div>
                        </el-dialog>  
             </ul>
        </div>
        <div class="content" style="padding:10px 40px;background:#f5f5f5">
                <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
   
    <el-tab-pane label="平台规则" name="first" style="text-align:center">
            <quill-editor ref="text" style="height:400px" v-model="content" class="myQuillEditor" :options="editorOption" />
            <el-button type="primary" @click="submit"  style="cursor: pointer; margin-top:50px;">提交</el-button>

    </el-tab-pane>
    <el-tab-pane label="晋升规则" name="second" style="text-align:center">
     <quill-editor ref="text1" style="height:400px" v-model="content1" class="myQuillEditor" :options="editorOption1" />
<el-button type="primary" @click="submit1"  style="cursor: pointer; margin-top:50px;">提交</el-button>
    </el-tab-pane>

     <el-tab-pane label="关于我们" name="third" style="text-align:center">
     <quill-editor ref="text2" style="height:400px" v-model="content2" class="myQuillEditor" :options="editorOption2" />
<el-button type="primary" @click="submit2"  style="cursor: pointer; margin-top:50px;">提交</el-button>
    </el-tab-pane>

      <el-tab-pane label="调律师须知" name="four" style="text-align:center">
     <quill-editor ref="text3" style="height:400px" v-model="content3" class="myQuillEditor" :options="editorOption3" />
<el-button type="primary" @click="submit3"  style="cursor: pointer; margin-top:50px;">提交</el-button>
    </el-tab-pane>

  </el-tabs>
        </div>
  </div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
export default {
    components: {
    quillEditor
  },
    data(){
        return{
               password:'',
                   password2:false,
                  password3:'',
               formLabelWidth: '120px',
            dialogFormVisible:false,
            info:[],
         activeName: 'first',
         tableData:'',
           tableData1:'',
             content: '',
             content3:'',
    editorOption: {} ,
      content1: '',
      content2:'',
    editorOption1: {} ,
        editorOption2: {} ,
            editorOption3: {} 
        }
    },
    methods:{
    handleClick(tab, event) {
          if(tab.name == 'first'){
              this.tab1()
          }else if(tab.name == 'second'){
              this.tab2()
          }else if(tab.name == 'third'){
              this.tab3()
          }else {
              this.tab4()
          }
    },
    tab1(){
        this.$axios.get("/s/backend/index/content_list", {
        params: { 'accessToken':localStorage.getItem('token'),
            'type':1
        }
        })

     .then(res=>{
            console.log()
            
             this.$axios({
                        method: "post",
                        url: "/s/backend/index/get_content",
                        data:{
                            id:res.data.result[res.data.result.length-1].id
                        }
                       
                        }) .then(res=>{
                            this.content = res.data.result[0].content
                        }).catch({})
        })
        .catch(function(){
           
        })
    },
    tab2(){
        this.$axios.get("/s/backend/index/content_list", {
        params: { 'accessToken':localStorage.getItem('token'),
            'type':0
        }
        })

     .then(res=>{
            console.log()
            
             this.$axios({
                        method: "post",
                        url: "/s/backend/index/get_content",
                        data:{
                            id:res.data.result[res.data.result.length-1].id
                        }
                       
                        }) .then(res=>{
                            this.content1 = res.data.result[0].content
                        }).catch({})
        })
        .catch(function(){
           
        })
    },

     tab3(){
        this.$axios.get("/s/backend/index/content_list", {
        params: { 'accessToken':localStorage.getItem('token'),
            'type':2
        }
        })

     .then(res=>{
            console.log()
            
             this.$axios({
                        method: "post",
                        url: "/s/backend/index/get_content",
                        data:{
                            id:15
                        }
                       
                        }) .then(res=>{
                            this.content2 = res.data.result[0].content
                        }).catch({})
        })
        .catch(function(){
           
        })
    },

 tab4(){
        this.$axios.get("/s/backend/index/content_list", {
        params: { 'accessToken':localStorage.getItem('token'),
            'type':3
        }
        })

     .then(res=>{
            console.log()
            
             this.$axios({
                        method: "post",
                        url: "/s/backend/index/get_content",
                        data:{
                            id:7
                        }
                       
                        }) .then(res=>{
                            this.content3 = res.data.result[0].content
                        }).catch({})
        })
        .catch(function(){
           
        })
    },


          tuichu(){
            this.dialogFormVisible = false;
            window.localStorage.removeItem('token')
                     this.$router.push({ name: 'login'})
        },
        a11(){
          this.$axios({
                        method: "post",
                        url: "/s/backend/api/userupdate",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:this.info,
                        }) .then(res=>{
                            this.dialogFormVisible = false
                              this.$message({
                   message:'修改成功',
                  type: 'success'
                     });
                        })
        .catch(function(){
           
        })  
        },
        a22(){
            this.password2=true
         
              
        },
        a33(){
               if(this.password ==''){
                this.$message({
                   message:'请输入新密码后点击修改',
                  type: 'success'
                     }); 
            }else{
                    this.$axios({
                            method: "post",
                            url: "/s/backend/api/changepass",
                            params: {
                            accessToken : localStorage.getItem('token')
                            },
                            data:{
                        "jobnumber":  localStorage.getItem('jobnumber'),
                        "newpassword": this.password,
                        "oldpassword": this.password3
                        },
                            }) .then(res=>{
                            
                            if(res.data.code == 0){
                                    this.dialogFormVisible = false
                                    this.$message({
                    message:'修改成功',
                    type: 'success'
                        });
                            }
                            })
            .catch(function(){
            
            })  
            }
        },
        submit1 () {
  
  this.$axios({
                            method: "post",
                            url: "/s/backend/index/edit_content",
                            params: {
                            accessToken : localStorage.getItem('token')
                            },
                            data:{
                       "content": this.$refs.text1.value,
                         "id": 14
                        },
                            }) .then(res=>{

                                 this.$axios.get("/s/backend/index/content_list", {
        params: { 'accessToken':localStorage.getItem('token'),
            'type':1
        }
        })

     .then(res=>{
            console.log()
            
             this.$axios({
                        method: "post",
                        url: "/s/backend/index/get_content",
                        data:{
                            id:res.data.result[res.data.result.length-1].id
                        }
                       
                        }) .then(res=>{
                            this.content = res.data.result[0].content
                        }).catch({})
        })
        .catch(function(){
           
        })

                            }).catch({

                            })


  this.$message({
                   message:'修改成功',
                  type: 'success'
                     });
  },
    submit () {
   this.$axios({
                            method: "post",
                            url: "/s/backend/index/edit_content",
                            params: {
                            accessToken : localStorage.getItem('token')
                            },
                            data:{
                       "content": this.$refs.text.value,
                         "id": 13
                        },
                            }) .then(res=>{
  this.$axios.get("/s/backend/index/content_list", {
        params: { 'accessToken':localStorage.getItem('token'),
            'type':0
        }
        })

     .then(res=>{
            console.log()
            
             this.$axios({
                        method: "post",
                        url: "/s/backend/index/get_content",
                        data:{
                            id:res.data.result[res.data.result.length-1].id
                        }
                       
                        }) .then(res=>{
                            this.content1 = res.data.result[0].content
                        }).catch({})
        })
        .catch(function(){
           
        })
                            }).catch({

                            })


  this.$message({
                   message:'修改成功',
                  type: 'success'
                     });
  },



 submit2 () {
   this.$axios({
                            method: "post",
                            url: "/s/backend/index/edit_content",
                            params: {
                            accessToken : localStorage.getItem('token')
                            },
                            data:{
                       "content": this.$refs.text2.value,
                         "id": 15
                        },
                            }) .then(res=>{
  this.$axios.get("/s/backend/index/content_list", {
        params: { 'accessToken':localStorage.getItem('token'),
            'type':2
        }
        })

     .then(res=>{
            console.log()
            
             this.$axios({
                        method: "post",
                        url: "/s/backend/index/get_content",
                        data:{
                            id:15
                        }
                       
                        }) .then(res=>{
                            this.content2 = res.data.result[0].content
                        }).catch({})
        })
        .catch(function(){
           
        })
                            }).catch({

                            })


  this.$message({
                   message:'修改成功',
                  type: 'success'
                     });
  },



 submit3() {
   this.$axios({
                            method: "post",
                            url: "/s/backend/index/edit_content",
                            params: {
                            accessToken : localStorage.getItem('token')
                            },
                            data:{
                       "content": this.$refs.text3.value,
                         "id": 7
                        },
                            }) .then(res=>{
  this.$axios.get("/s/backend/index/content_list", {
        params: { 'accessToken':localStorage.getItem('token'),
            'type':3
        }
        })

     .then(res=>{
            console.log()
            
             this.$axios({
                        method: "post",
                        url: "/s/backend/index/get_content",
                        data:{
                            id:7
                        }
                       
                        }) .then(res=>{
                            this.content3 = res.data.result[0].content
                        }).catch({})
        })
        .catch(function(){
           
        })
                            }).catch({

                            })


  this.$message({
                   message:'修改成功',
                  type: 'success'
                     });
  },






        go(){
              this.$router.go(-1)
        }
    },
    mounted(){




 this.$axios.get("/s/backend/index/content_list", {
        params: { 'accessToken':localStorage.getItem('token'),
            'type':1
        }
        })

     .then(res=>{
            console.log()
            
             this.$axios({
                        method: "post",
                        url: "/s/backend/index/get_content",
                        data:{
                            id:res.data.result[res.data.result.length-1].id
                        }
                       
                        }) .then(res=>{
                            this.content = res.data.result[0].content
                        }).catch({})
        })
        .catch(function(){
           
        })
      

  this.$axios.get("/s/backend/api/user_detail", {
        params: { 'accessToken':localStorage.getItem('token'),
        'jobnumber':localStorage.getItem('jobnumber')
        }
        })

     .then(res=>{
          this.info = res.data.data
       
        })
        .catch(function(){
           
        })
     
    }
}
</script>

<style scoped>
     .header{

min-height: 57px;        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
    }
    .header .left_menu{
        display: flex;   width: 100%;  padding:0 40px;
        justify-content: space-between;
    }
    .header .left_menu .item{
        display: flex;
        align-items: center;
    }
        .header .left_menu .item .a{
            margin-right: 60px;  
        }
           .header .left_menu .item li a{
               text-decoration: none;color: #000;font-size: 16px;
font-weight: 500;
           }

        .header .active{
            color: #0034FF;
            position:relative;
        }
             .header .active::before{
                      content: "";
   
         left: 0;
    position: absolute;
    bottom:-120%;
    height:3px;
    width:30px;
    background: #0034FF;
            }
  /deep/.el-dialog__wrapper{
    top:-50px
}

/deep/ .el-dialog{
        width: 25%;

    float: right;
  
}

  /deep/.el-table th > .cell {
      text-align: center;

    }

    /deep/.el-table .cell {
      text-align: center;

    }

/deep/.el-tabs--card>.el-tabs__header{
    border-bottom: none !important;
}

/deep/ .el-tabs--card>.el-tabs__header .el-tabs__item{
    border-bottom:1px solid #E4E7ED;}
/deep/ .el-tabs__item.is-active{
    border:1px solid #409EFF;
    border-left:1px solid #409EFF !important;
      border-bottom:1px solid #409EFF !important;
}
</style>